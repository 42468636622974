import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthLayoutComponent } from '../../layout/auth-layout/auth-layout.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { UnauthenticatedRedirectGuard } from '../../core/guards/unauthenticated-redirect-guard';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VerifyEmailInstructionsComponent } from './verify-email-instructions/verify-email-instructions.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { EnterVerifyCodeComponent } from './enter-verify-code/enter-verify-code.component';


const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    canActivate: [UnauthenticatedRedirectGuard],
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'enter-verify-code',
        component: EnterVerifyCodeComponent,
      },
      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'verify-email-instructions',
        component: VerifyEmailInstructionsComponent
      },
      {
        path: 'verify-email',
        component: VerifyEmailComponent
      },
      {
        path: 'contact-us',
        component: ContactUsComponent
      },
      { path: '**', redirectTo: '/login' }
    ]
  },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
