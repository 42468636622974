import { Permissions } from './permission.model';

export interface LoginResponse {
  access?: string;
  avatar: string;
  created_at: string;
  email: string;
  id: number;
  is_2fa_enabled: boolean;
  is_email_validated: boolean;
  is_trusted_user: boolean;
  is_onboarded_completely: boolean;
  name: string;
  permissions: Permissions;
  phone_number: string;
  refresh: string;
  slack_id: string;
  code: string;
}

export interface Session {
  token: string;
  user_permissions: Permissions;
  user_details: UserDetails;
}

export interface UserDetails {
  id?: number;
  name?: string;
  email?: string;
  phone_number?: string;
  avatar?: string;
  is_2fa_enabled?: boolean;
  is_trusted_user?: boolean;
  is_onboarded_completely?: boolean;
  has_rolling_deal_review_access?: boolean;
  has_company?: boolean;
  permissions: Permissions;
}

export enum AccountTab {
  UserInfo,
  CompanyInfo,
  InsurableInterest,
  RegulatoryDisclaimers,
  AlertSubscription,
  NeedHelp,
  PrivacySecurity
}
