<div class="w-[900px] min-h-[700px] relative bg-white md:rounded-3xl md:shadow-md">
  <dd-side-panel></dd-side-panel>
  <div class="py-60 px-100 ml-300">
    <div class="text-35 font-bold mb-8">Verify Email Instructions</div>
    <p class="text-16 text-grey">
      Once you verify your email address, you may get started with the Home of Hedging.
    </p>

    <div class="flex flex-col mt-32 gap-y-16">
      <p class="text-16">Proceed with your Email Provider:</p>

      <div class="flex mt-16 gap-x-16">
        <div *ngFor="let button of buttonsConfig">
          <button mat-stroked-button (click)="openEmailProvider(button.id)">
            <div class="flex items-center justify-start gap-x-16">
              <img
                [src]="button.icon"
                alt="{{button.alt}}"
                class="cursor-pointer"
                loading="lazy"
              />
              <span class="text-base">{{button.label}}</span>
            </div>
          </button>
        </div>
      </div>
      <p class="text-[14px] text-grey mt-24">
        Didn't receive any email? Please remember to check your spam folder in case it doesn't appear in your inbox.
        <a (click)="resendEmail()"
           class="text-blue-500 underline hover:text-blue-800"
        >Resend email</a>
      </p>
    </div>

    <p class="text-[12px] text-grey mt-40 text-right">Go back to <a class="text-blue-400 underline" routerLink="/login">Sign in</a></p>

  </div>
</div>
