<div class="w-[900px] h-[780px] relative bg-white md:rounded-3xl md:shadow-md">
  <dd-side-panel></dd-side-panel>
  <div class="flex flex-col py-60 px-100 ml-300 gap-16">
    <h1 class="text-center font-bold mb-20">Two-Factor Authentication</h1>
    <div class="flex flex-col justify-center items-center">
      <p class="text-[14px] mb-20">
        Your account is protected with two-factor authentication. We've sent you a email with code. Please enter the
        code below.
      </p>

      <div class="mx-[-10px]">
        <code-input
          #codeInput
          [code]="otp"
          [initialFocusField]="0"
          [codeLength]="6"
          (codeChanged)="onOtpChanged($event)"
          (codeCompleted)="onOtpCompleted($event)"
        >
        </code-input>
      </div>
    </div>

    <div class="text-center">
      <button
        color="primary"
        mat-flat-button
        class="!my-16 mx-auto"
        [disabled]="!isTimeFinished || isLoading"
        (click)="resendAuthEmail()"
      >
      <span
      >Resend code via email
        <ng-container *ngIf="timer$ | async as timerValue">
          <span [hidden]="isTimeFinished">again in {{ timerValue }}s</span>
        </ng-container>
      </span>
      </button>
    </div>

    <p *ngIf="isInvalidOtp" class="text-14 text-red-500">
      The One Time Password you provided doesn't seem to match the one provided to your email. <br />
      If the problem persists, please contact Stable team.
    </p>

  </div>
</div>
