<div class="flex justify-end">
  <div
    class="flex items-center p-5 mr-20 mt-20 bg-white border-2 border-gray-100 drop-shadow-md rounded-md w-fit max-w-600"
  >
    <ng-container [ngSwitch]="options.payload">
      <ng-container *ngSwitchCase="ToastType.Success">
        <mat-icon aria-hidden="false" class="text-energy pr-30 mr-10 align-middle"> check_circle_outline</mat-icon>
      </ng-container>
      <ng-container *ngSwitchCase="ToastType.Error">
        <mat-icon aria-hidden="false" class="text-carnation pr-30 mr-10 align-middle"> cancel</mat-icon>
      </ng-container>
      <ng-container *ngSwitchCase="ToastType.Warning">
        <mat-icon aria-hidden="false" class="text-honey pr-30 mr-10 align-middle"> warning</mat-icon>
      </ng-container>
      <ng-container *ngSwitchCase="ToastType.Info">
        <mat-icon aria-hidden="false" class="text-cerulean pr-30 mr-10 align-middle"> info</mat-icon>
      </ng-container>
    </ng-container>
    <span [innerHTML]="message" class="mr-10 text-gray-900 whitespace-pre-wrap"></span>
  </div>
</div>
