import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  storage = {
    accessToken: 'access_token',
  };

  constructor() {}

  setItem(name: string, value: any) {
    localStorage.setItem(name, value);
  }

  getItem(name: string): any {
    return localStorage.getItem(name) || '';
  }

  removeItem(name: string) {
    localStorage.removeItem(name);
  }

  setSession(token: string) {
    this.setItem(this.storage.accessToken, token);
  }

  getSession(): string {
    return this.getItem(this.storage.accessToken);
  }

  killSession() {
    this.removeItem(this.storage.accessToken);
  }

}
