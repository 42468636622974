import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { catchError, EMPTY, finalize } from 'rxjs';

import { AuthService } from '../../../core/services/auth.service';
import { ToastService } from '../../../ui-kit/toast/toast.service';
import { LoginForm, loginFormGroup } from '../../../core/strict-typed-forms/auth.form';
import { SentryService } from '../../../core/services/sentry.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ERROR_MESSAGES } from '../../../constants';
import { HttpStatusCode } from '@angular/common/http';
import { NavigationService } from '../../../core/services/navigation.service';

export enum INFO_MESSAGES {
  UNVERIFIED_EMAIL = `Your email is unverified. You'll be redirected to the instructions page for your initial login process.`,
  RESET_PASSWORD = `We've done some changes that require you to reset your password.`,
}

@Component({
  selector: 'dd-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  form: FormGroup<LoginForm> = this.fb.nonNullable.group({ ...loginFormGroup });

  isLoading = false;

  private cookieService = inject(CookieService);

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private toast: ToastService,
    private sentryService: SentryService,
    private router: Router,
    private navigationService: NavigationService
  ) {
  }

  login() {
    const { email, password } = this.form.value;

    if (!email || !password) {
      return;
    }

    this.isLoading = true;

    this.authService.login(email, password).pipe(
      catchError((e: any) => {
        const errorCode = e?.error?.error_code;

        if (errorCode === 'email_not_verified') {
          this.setCookieWithExpiry(email);
          this.toast.info(INFO_MESSAGES.UNVERIFIED_EMAIL)
          this.router.navigate(['/verify-email-instructions']);
          return EMPTY;
        }

        if (errorCode === 'password_change_required') {
          this.setCookieWithExpiry(email);
          this.toast.info(INFO_MESSAGES.RESET_PASSWORD);
          this.router.navigate(['/forgot-password']);
          return EMPTY;
        }

        const errorDetail = e?.error?.detail || ERROR_MESSAGES.GENERAL_ERROR;
        this.toast.error(errorDetail);

        return this.sentryService.sendException(e);
      }),
      finalize(() => this.isLoading = false)
    ).subscribe((response) => {
      if (response.status === HttpStatusCode.Ok) {
        if (!response.body) {
          this.toast.error(ERROR_MESSAGES.GENERAL_ERROR);
          this.sentryService.sendException(ERROR_MESSAGES.BYPASS_TWO_FACTOR_AUTHENTICATION);
          return;
        }

        this.authService.byPassTwoFactorAuthentication(response.body);
        this.navigationService.handleLoginRedirection(response.body);
      }
      if (response.status === HttpStatusCode.Accepted) {
        this.router.navigate(['/enter-verify-code'], { queryParams: { code: response?.body?.code } })
      }

    });
  }

  private setCookieWithExpiry(email: string) {
    const cookieExpiryTime = new Date();
    cookieExpiryTime.setHours(cookieExpiryTime.getHours() + 1);

    this.cookieService.set('email', JSON.stringify(email), cookieExpiryTime);
  }
}
