import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { captureException, captureMessage, SeverityLevel } from "@sentry/angular-ivy";

export enum SentrySeverity {
  Fatal = "fatal",
  Error = "error",
  Warning = "warning",
  Log = "log",
  Info = "info",
  Debug = "debug"
}

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  sendFatalMessage(error: any): Observable<never> {
    return this.sendMessage(error, SentrySeverity.Fatal);
  }

  sendErrorMessage(error: any): Observable<never> {
    return this.sendMessage(error, SentrySeverity.Error)
  }

  sendWarningMessage(error: any): Observable<never> {
    return this.sendMessage(error, SentrySeverity.Warning)
  }

  sendLogMessage(error: any): Observable<never> {
    return this.sendMessage(error, SentrySeverity.Log)
  }

  sendInfoMessage(error: any): Observable<never> {
    return this.sendMessage(error, SentrySeverity.Info)
  }

  sendDebugMessage(error: any): Observable<never> {
    return this.sendMessage(error, SentrySeverity.Debug)
  }

  sendException(error: any) {
    captureException(error)
    return EMPTY;
  }

  private sendMessage(error: any, severity?: SeverityLevel): Observable<never> {
    captureMessage(error, severity)
    return EMPTY;
  }


}
