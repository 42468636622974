import { ToastPriority, ToastType } from './toast.model';
import { IndividualConfig } from "ngx-toastr/toastr/toastr-config";

export function getToastConfig(payload: ToastType, priority?: ToastPriority): Partial<IndividualConfig> {
  let timeOut = 4000;
  switch (priority) {
    case ToastPriority.Medium:
      timeOut = 5000;
      break;
    case ToastPriority.High:
      timeOut = 8000;
      break;
    default:
      break;
  }
  return {
    timeOut,
    tapToDismiss: true,
    closeButton: false,
    newestOnTop: true,
    payload
  };
}
