import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { Observable, of, switchMap, take } from 'rxjs';

import { dataDashRoute } from '../routes/data-dash.route';
import { AuthService } from '../services/auth.service';
import { portfolioManageSystemRoute } from '../routes/portfolio-manage-system.route';

@Injectable({
  providedIn: 'root'
})
export class UnauthenticatedRedirectGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isUserAuthenticated = this.authService.isUserAuthenticated();
    if (!isUserAuthenticated) {
      return true;
    }

    return this.authService.getUserInfo().pipe(
      take(1),
      switchMap((currentUser) => {
        if (currentUser.is_trusted_user) {
          if (currentUser.permissions?.data_center_access || currentUser.permissions?.data_dash_access) {
            this.router.navigate(['/']);
          } else if (currentUser.permissions?.has_pms_access) {
            this.router.navigate(['/' + portfolioManageSystemRoute.root, portfolioManageSystemRoute.home]);
          }
          return of(true);
        }
        return this.router.navigate(['/' + dataDashRoute.root, dataDashRoute.tradingCenter]).then(() => false);
      })
    );
  }
}
