import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, map, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Company, TradeCommodity } from '../models/company.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  company: Company;
  isECPRegistered: boolean = false;
  isLEIRegistered: boolean = false;
  commodities: TradeCommodity[] = [];

  constructor(private http: HttpClient) {}

  getCompany(): Observable<Company> {
    const url = `${environment.api}/user/company/`;
    return this.http.get<Company>(url);
  }

  setCompany(company: Company): Observable<Company> {
    const url = `${environment.api}/user/company/save/`;
    return this.http.post<Company>(url, company);
  }

  getTradeCommodities(): Observable<TradeCommodity[]> {
    const url = `${environment.api}/user/company/trade-commodities/`;
    return this.http.get<TradeCommodity[]>(url).pipe(
      map((res) => {
        res = res.map((item) => ({
          ...item,
          klass_id: item.klass?.id
        }));
        return res;
      })
    );
  }

  setTradeCommodities(commodities: { commodities: TradeCommodity[] }): Observable<TradeCommodity[]> {
    const url = `${environment.api}/user/company/trade-commodities/bulk-save/`;
    return this.http.post<TradeCommodity[]>(url, commodities);
  }

  async prepareCompanyData() {
    try {
      this.company = await firstValueFrom(this.getCompany());
    } catch (e) {
      console.log(e);
    } finally {
      if (this.company) {
        this.isECPRegistered = Boolean(this.company.ecp_disclaimer);
        this.isLEIRegistered = Boolean(this.company.legal_entity_number);
        this.commodities = await firstValueFrom(this.getTradeCommodities());
      } else {
        this.isECPRegistered = false;
        this.isLEIRegistered = false;
        this.commodities = [];
      }
    }
  }

  checkCommodityRegistered(commodityClassId: string): boolean {
    return Boolean(
      this.commodities.filter(
        (commodity) => commodity.klass_id === commodityClassId
      ).length
    );
  }
}
