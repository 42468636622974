import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { catchError, finalize, Observable } from 'rxjs';

import { ToastService } from '../../../ui-kit/toast/toast.service';
import { ResetPasswordForm, resetPasswordFormGroup } from '../../../core/strict-typed-forms/auth.form';
import { UserDetailsService } from '../../../core/services/user-details.service';
import { RegistrationError } from '../register/register.component';
import { SentryService } from '../../../core/services/sentry.service';
import { ERROR_MESSAGES } from '../../../constants';

@Component({
  selector: 'dd-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup<ResetPasswordForm> = this.fb.nonNullable.group({ ...resetPasswordFormGroup });

  isLoading = false;
  hasWeakPassword: boolean;

  constructor(
    private fb: FormBuilder,
    private userDetailsService: UserDetailsService,
    private toast: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sentryService: SentryService
  ) {}

  ngOnInit() {
    this.extractQueryParams();
  }

  async sendLink() {
    const { user_id, password, change_password_id } = this.form.value;

    if (!user_id || !password || !change_password_id) {
      return;
    }

    this.isLoading = true;

    this.userDetailsService
      .resetPassword(password, user_id, change_password_id)
      .pipe(
        catchError((err) => this.handleRegisterError(err)),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(() => {
        this.toast.success('You have successfully changed your password.');
        this.router.navigate(['/login']);
      });
  }

  private handleRegisterError(err: any): Observable<never> {
    const error: RegistrationError = err.error;

    const errorCode = error?.error_code;

    if (errorCode === 'invalid_password') {
      this.hasWeakPassword = true;
      return this.sentryService.sendException(error);
    }

    this.hasWeakPassword = false;

    const errorDetail = error?.detail || ERROR_MESSAGES.GENERAL_ERROR;
    this.toast.error(errorDetail);
    return this.sentryService.sendException(error);
  }

  private extractQueryParams() {
    this.activatedRoute.queryParams.subscribe((params) => {
      params['change_password_id'] && this.form.controls.change_password_id.setValue(params['change_password_id']);
      params['user_id'] && this.form.controls.user_id.setValue(params['user_id']);
    });
  }
}
