/**
 * This code returns the getter and setter for a property in an object.
 *
 * @param {T} target Target Object
 * @param {K} propertyKey Target Property
 * @return {*}
 */
export const checkDescriptor = <T, K extends keyof T>(target: T, propertyKey: K) => {
  const oDescriptor = Object.getOwnPropertyDescriptor(target, propertyKey);

  if (oDescriptor && !oDescriptor.configurable) {
    throw new TypeError(`property ${String(propertyKey)} is not configurable`);
  }

  return {
    oGetter: oDescriptor?.get,
    oSetter: oDescriptor?.set,
    oDescriptor
  };
};

export function propDecoratorFactory<T, D>(
  name: string,
  fallback: (v: T) => D
): (target: any, propName: string) => void {
  function propDecorator(target: any, propName: string, originalDescriptor?: TypedPropertyDescriptor<any>): any {
    const privatePropName = `$$__PropDecorator__${propName}`;

    if (Object.prototype.hasOwnProperty.call(target, privatePropName)) {
      console.warn(`The prop "${privatePropName}" is already exist, it will be overrided by ${name} decorator.`);
    }

    Object.defineProperty(target, privatePropName, {
      configurable: true,
      writable: true
    });

    return {
      get(): string {
        return originalDescriptor && originalDescriptor.get
          ? originalDescriptor.get.bind(this)()
          : this[privatePropName];
      },
      set(value: T): void {
        if (originalDescriptor && originalDescriptor.set) {
          originalDescriptor.set.bind(this)(fallback(value));
        }
        this[privatePropName] = fallback(value);
      }
    };
  }

  return propDecorator;
}
