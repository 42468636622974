<div class="flex flex-col items-stretch w-[900px] min-h-[700px] relative bg-white md:rounded-3xl md:shadow-md h-full">
  <dd-side-panel></dd-side-panel>
  <div class="absolute py-60 px-100 ml-300 top-0 bottom-0">
    <div class="text-35 font-bold mb-8">Verify Email</div>

    <p class="text-16 text-grey">
      We're verifying the email. After that, you may get started with the Home of Hedging.
    </p>

    <div class="m-auto">
      <dd-spinner *ngIf="isLoading" message="Verifying Email..."></dd-spinner>
    </div>
  </div>
</div>
