import { Injectable } from '@angular/core';
import { LoginResponse } from '../models/auth.model';
import { dataDashRoute } from '../routes/data-dash.route';
import { portfolioManageSystemRoute } from '../routes/portfolio-manage-system.route';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router,
              private authService: AuthService) { }

  handleLoginRedirection(response: LoginResponse) {
    const hasDataCenterOrDashAccess = response.permissions?.data_center_access || response.permissions?.data_dash_access;
    const hasPmsAccess = response.permissions?.has_pms_access;

    if (!response.is_trusted_user) {
      return this.router.navigate(['/' + dataDashRoute.root, dataDashRoute.tradingCenter]);
    }

    const redirectUrl = this.authService.getRedirectUrl();

    if (redirectUrl) {
      return this.router.navigate([redirectUrl]);
    }

    if (hasDataCenterOrDashAccess) {
      return this.router.navigate(['/']);
    } else if (hasPmsAccess) {
      return this.router.navigate(['/' + portfolioManageSystemRoute.root, portfolioManageSystemRoute.home]);
    } else {
      return this.router.navigate(['/']);
    }
  }
}
