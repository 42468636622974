import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, RetryConfig, catchError, throwError, timer } from 'rxjs';
import { retry } from 'rxjs/operators';

import { Router } from '@angular/router';
import { ToastPriority, ToastType } from '../../ui-kit/toast/toast.model';
import { ToastService } from '../../ui-kit/toast/toast.service';
import { CloudinaryContext } from '../../ui-kit/utils/cloudinary.util';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { SentryService } from '../services/sentry.service';

interface SentryError {
  message: string;
  url?: string;
  status?: HttpStatusCode;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private toastService: ToastService,
    private sentryService: SentryService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.localStorageService.getSession();
    let duplicate = request;
    const isCloudinary = request.context.get(CloudinaryContext);
    if (isCloudinary) {
      duplicate = request.clone();
    } else {
      duplicate = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`)
      });
    }

    return next.handle(duplicate).pipe(
      catchError((error) => {
        this.sendErrorToSentry(error);

        if (error instanceof HttpErrorResponse) {
          if (!navigator?.onLine) {
            this.toastService.error(
              `It seems like there's a problem with your internet connection. Please check your connection and try again.`,
              ToastPriority.High
            );
          }
        }

        if (isCloudinary) {
          return throwError(error);
        }

        if (error.status === 401) {
          // Not authenticated
          this.toastService.stream$.next({
            type: ToastType.Error,
            message: 'Credentials has expired, please log in again.',
            priority: ToastPriority.High
          });
          this.authService.logout();
          this.authService.setRedirectUrl(this.router.routerState.snapshot.url);
        } else if (error.status === 403) {
          // Invalid permissions
          this.toastService.stream$.next({
            type: ToastType.Error,
            message: 'Credentials has been changed, please log in again.',
            priority: ToastPriority.High
          });
          this.authService.logout();
        }
        return throwError(error);
      }),
      retry(this.httpErrorResponseRetryConfig())
    );
  }

  private sendErrorToSentry(error: any) {
    this.sentryService.sendException(error);

    const errorCaught: SentryError = {
      ...(error.status && { status: error.status }),
      ...(error.message && { message: error.message }),
      ...(error.url && { url: error.url })
    };

    this.sentryService.sendInfoMessage(JSON.stringify(errorCaught));
  }

  /**
   * Retry in case of 408 >= && <= 599
   * @private
   */
  private httpErrorResponseRetryConfig(): RetryConfig {
    let count = 0;

    const delay = (error: HttpErrorResponse) => {
      if (error.status >= 408 && error.status <= 599) {
        count = 1;
        return timer(3000);
      }
      return throwError(error);
    };

    return { count, delay };
  }
}
