<div class="w-[900px] min-h-[700px] relative bg-white md:rounded-3xl md:shadow-md">
  <dd-side-panel></dd-side-panel>
  <div class="py-60 px-100 ml-300">


    <div *ngIf="!showInstructions else instructionsTemplate">
      <div class="text-35 font-bold mb-10">Forgot Password</div>
      <p class="text-[12px] text-grey mb-30">Fill in your email.</p>

      <form (ngSubmit)="sendLink()" [formGroup]="form" class="">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput placeholder="john.doe@stableprice.com" type="email" />
          <mat-error *ngIf="form.controls.email?.hasError('required')">Email is required.</mat-error>
          <mat-error *ngIf="form.controls.email?.hasError('email')">Invalid email address form.</mat-error>
        </mat-form-field>

        <button [disabled]="form.invalid || isLoading" class="w-full" color="primary" mat-flat-button>
          {{ isLoading ? 'Submitting...' : 'Send reset link' }}
        </button>
      </form>
    </div>


    <ng-template #instructionsTemplate>
      <div class="text-35 font-bold mb-10">Instructions Sent</div>

      <div class="flex flex-col place-items-center py-16">
        <div class="flex flex-col gap-y-32 mt-30">
          <span class="text-base">If your account exists, an email will be sent to you with instructions to reset your password, for this email:
            <p class="font-bold inline-block">{{ form.value.email }}</p>
          </span>

          <p class="text-base">You should expect to receive this email within 5 minutes. Please remember to check your spam folder in case it doesn't appear in your inbox.</p>
        </div>

        <div>

        </div>
      </div>
    </ng-template>

    <p class="text-[12px] text-grey mt-30 text-right">Go back to <a class="text-blue-400 underline" routerLink="/login">Sign in</a></p>
  </div>

</div>
