<div class="border border-gray-200 p-8 rounded-lg mt-8">
  <p class="text-red-500 my-8 ml-4">Your password must be strong and contain:</p>
  <ul class="list-disc ml-4 list-inside">
    <li class="m-2">At least 8 characters</li>
    <li class="m-2">
      At least 3 of the following:
      <ul class="list-[circle] ml-8 list-inside">
        <li class="m-2">Lower case letters (a-z)</li>
        <li class="m-2">Upper case letters (A-Z)</li>
        <li class="m-2">Numbers (0-9)</li>
        <li class="m-2">Special characters (e.g. !@#$%^&amp;*)</li>
      </ul>
    </li>
  </ul>
</div>
