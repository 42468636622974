import { Component, OnInit } from '@angular/core';
import { catchError, finalize } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../ui-kit/toast/toast.service';
import { UserDetailsService } from '../../../core/services/user-details.service';
import { SentryService } from '../../../core/services/sentry.service';
import { ERROR_MESSAGES } from '../../../constants';

export const VERIFICATION_SUCCESS_MESSAGE = "Email verified successfully! Feel free to login and welcome to the Home of Hedging";

@Component({
  selector: 'dd-verify-email',
  templateUrl: './verify-email.component.html'
})
export class VerifyEmailComponent implements OnInit {

  isLoading = true;


  constructor(private activatedRoute: ActivatedRoute,
              private toastService: ToastService,
              private router: Router,
              private userDetailsService: UserDetailsService,
              private sentryService: SentryService) { }

  ngOnInit(): void {
    this.extractQueryParams();
  }

  private extractQueryParams(): void {
    this.activatedRoute.queryParams.subscribe(({ verification_id, user_id }) => {

      if (!verification_id || !user_id) {
        this.toastService.error('There was a problem loading this page. If it persists, please contact the admins!');
        this.sentryService.sendInfoMessage('Problem loading params in verification email');
        this.isLoading = false;
        this.router.navigate(['/login']);
        return;
      }
      this.verifyEmail(verification_id, user_id);
    });
  }

  private verifyEmail(verification_id: string, user_id: string): void {
    this.userDetailsService
      .verifyEmail(verification_id, user_id)
      .pipe(
        catchError((e) => {
          const errorDetail = e?.error?.detail || ERROR_MESSAGES.GENERAL_ERROR;
          this.toastService.error(errorDetail);
          return this.sentryService.sendInfoMessage('Problem verifying email');
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(() => {
        this.toastService.success(VERIFICATION_SUCCESS_MESSAGE);
        this.router.navigate(['/login']);
      });
  }

}
