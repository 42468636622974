import { DragDropModule } from '@angular/cdk/drag-drop';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TraceModule, createErrorHandler } from '@sentry/angular-ivy';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { AuthModule } from './pages/auth/auth.module';
import { SnackbarModule } from './ui-kit/snackbar/snackbar.module';
import { ToastModule } from './ui-kit/toast/toast.module';
import { PMSRedirectComponent } from './shared/pms-redirect/pms-redirect.component';

@NgModule({
  declarations: [AppComponent, PMSRedirectComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SnackbarModule,
    AppRoutingModule,
    // Routes of authentication pages are defined in the AuthModule
    AuthModule,
    DragDropModule,
    ToastModule.forRoot(),
    MatDialogModule,
    TraceModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useValue: createErrorHandler() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
