import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import {
  ApplicationRef,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf
} from '@angular/core';
import { combineLatest, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { ObservableInput } from '../../core/decorators/observable-input.decorator';
import { SpinnerComponent } from './spinner.component';

/**
 * host element should be position relative
 *
 * @export
 * @class DdSpinnerDirective
 * @implements {OnDestroy}
 * @implements {OnInit}
 */
@Directive({ selector: '[ddSpinning]' })
export class DdSpinnerDirective implements OnDestroy, OnInit {
  @Input() ddSpinning: boolean | null | undefined;
  @ObservableInput() ddSpinning$!: Observable<boolean>;

  @Input() ignoreParent: boolean = false; // by default if parent are Spinning, don't this don't show Spinning
  @ObservableInput() ignoreParent$!: Observable<boolean>;

  @Input() ddSpinnerMessage: string = 'Loading...';
  @ObservableInput() ddSpinnerMessage$!: Observable<string>;

  @Input() ddSpinnerBgClass: string;
  @ObservableInput() ddSpinnerBgClass$: Observable<string>;

  component: ComponentRef<SpinnerComponent>;

  loading$: Observable<boolean> = combineLatest([
    this.ignoreParent$,
    this.ddSpinning$,
    this.parentSpinnerDirective?.loading$ || of(false)
  ]).pipe(
    map(([ignoreParent, loading, parentLoading]) => {
      if (ignoreParent) {
        return !!loading;
      }
      return parentLoading ? false : loading;
    })
  );

  private unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private readonly appRef: ApplicationRef,
    private readonly cfr: ComponentFactoryResolver,
    @Optional() @SkipSelf() private parentSpinnerDirective: DdSpinnerDirective
  ) {}

  ngOnInit(): void {
    const spinnerPortal = new ComponentPortal(SpinnerComponent);
    const portalOutlet = new DomPortalOutlet(this.elementRef.nativeElement, this.cfr, this.appRef);
    this.component = spinnerPortal.attach(portalOutlet);

    this.ddSpinnerMessage$.pipe(takeUntil(this.unsubscribeAll)).subscribe((message) => {
      this.component.instance.message = message;
      this.component.injector.get(ChangeDetectorRef).markForCheck();
    });

    this.ddSpinnerBgClass$.pipe(takeUntil(this.unsubscribeAll)).subscribe((bgClass) => {
      this.component.instance.bgClass = bgClass;
      this.component.injector.get(ChangeDetectorRef).markForCheck();
    });

    this.loading$.pipe(takeUntil(this.unsubscribeAll)).subscribe((loading) => {
      this.component.instance.spinner = loading;
      this.component.injector.get(ChangeDetectorRef).markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
