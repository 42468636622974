import { HttpContextToken } from '@angular/common/http';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { environment } from '../../../environments/environment';

export const CloudinaryContext = new HttpContextToken<boolean>(() => false);

export const getCloudinaryUrl = (publicID: string, transformer?: (image: CloudinaryImage) => CloudinaryImage) => {
  if (transformer) {
    return transformer(new CloudinaryImage(publicID, { cloudName: environment.cloudinaryCloudName }))
      .quality('auto')
      .toURL();
  }
  return new CloudinaryImage(publicID, { cloudName: environment.cloudinaryCloudName }).quality('auto').toURL();
};
