import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

import { map, Observable, of } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isUserAuthenticated = this.authService.isUserAuthenticated();

    if (!isUserAuthenticated) {
      this.authService.logout();
      this.authService.setRedirectUrl(state.url);
      return of(false);
    }

    const isUserLoaded = this.authService.isUserLoaded();

    if (isUserLoaded) {
      return of(true);
    } else {
      return this.authService.getUserInfo().pipe(map(() => true));
    }
  }
}
