<div class="w-[900px] min-h-[700px] relative bg-white md:rounded-3xl md:shadow-md">
  <dd-side-panel></dd-side-panel>
  <div class="py-60 px-100 ml-300">
    <div class="text-35 font-bold mb-10">Get in touch!</div>
    <div class="text-[12px] text-grey mb-30">Let's have a chat</div>

    <form (ngSubmit)="submit()" [formGroup]="form">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Name</mat-label>
        <input formControlName="name" matInput placeholder="John Doe" type="text"/>
        <mat-error *ngIf="form.get('name')?.hasError('required')">Name is required.</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput placeholder="john.doe@mail.com" type="email"/>
        <mat-error *ngIf="form.get('email')?.hasError('required')">Email is required.</mat-error>
        <mat-error *ngIf="form.get('email')?.hasError('email')">Invalid email address.</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Subject</mat-label>
        <input formControlName="subject" matInput placeholder="E.g. Partnership Opportunity" type="text"/>
        <mat-error *ngIf="form.get('subject')?.hasError('required')">Subject is required.</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Message</mat-label>
        <textarea formControlName="message" matInput placeholder="Your message here"></textarea>
        <mat-error *ngIf="form.get('message')?.hasError('required')">Message is required.</mat-error>
      </mat-form-field>

      <button [disabled]="form.invalid || isLoading" class="w-full" color="primary" mat-flat-button>
        {{ isLoading ? 'Submitting...': 'Send Message' }}
      </button>

      <p class="text-[12px] text-grey mt-30 text-right">
        Go back to <a class="text-blue-400 underline" routerLink="/login">Sign in</a>
      </p>
    </form>
  </div>
</div>
