import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export interface LoginForm {
  email: FormControl<string>;
  password: FormControl<string>;
}

export const loginFormGroup = {
  email: ['', [Validators.required, Validators.email]],
  password: ['', Validators.required]
};

export interface RegisterForm {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
  password: FormControl<string>;
  hasAgreedWithTermsPrivacy: FormControl<boolean>;
}

export const registerFormGroup = {
  firstName: ['', Validators.required],
  lastName: [''],
  email: ['', [Validators.required, Validators.email]],
  password: ['', [Validators.required, Validators.minLength(8), passwordValidator()]],
  hasAgreedWithTermsPrivacy: [false, Validators.requiredTrue]
};

export interface ForgotPasswordForm {
  email: FormControl<string>;
}

export interface ResetPasswordForm {
  user_id: FormControl<string>;
  password: FormControl<string>;
  change_password_id: FormControl<string>;
}

export const forgotPasswordFormGroup = {
  email: ['', [Validators.required, Validators.email]]
};

export const resetPasswordFormGroup = {
  user_id: ['', [Validators.required]],
  password: ['', [Validators.required, Validators.minLength(8), passwordValidator()]],
  change_password_id: ['', [Validators.required]]
};

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value || value.length < 8) {
      return null;
    }

    const hasMinLength = value.length >= 8;
    const hasUpperCase = /[A-Z]+/.test(value);
    const hasLowerCase = /[a-z]+/.test(value);
    const hasNumeric = /[0-9]+/.test(value);
    const hasSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value);

    const isPasswordValid = ( Number(hasUpperCase) + Number(hasLowerCase) +
      Number(hasNumeric) + Number(hasSpecialCharacter) ) >= 3;

    return ( !isPasswordValid || !hasMinLength )
      ? { passwordInvalid: true }
      : null;
  };
}
