import { BehaviorSubject, Observable } from 'rxjs';
import { checkDescriptor } from './decorate.utils';

export type ObservableType<T> = T extends Observable<infer R> ? R : never;

const strictCheckDescriptor = <T, K extends keyof T>(target: T, propertyKey: K) => {
  const { oGetter, oSetter, oDescriptor } = checkDescriptor(target, propertyKey);

  if (oGetter || oSetter) {
    throw new TypeError(`property ${String(propertyKey)} should not define getter or setter`);
  }

  return oDescriptor;
};

export function ObservableInput<T = any, OK extends keyof T = any, K extends keyof T = any>(
  propertyKey: K | boolean = true,
  initialValue?: ObservableType<T[OK]>
) {
  return (target: T, oPropertyKey: OK) => {
    if (!(oPropertyKey as string).endsWith('$')) {
      throw new TypeError(`property ${oPropertyKey as string} should be an Observable and its name should end with $`);
    }

    strictCheckDescriptor(target, oPropertyKey);

    const symbol = Symbol(oPropertyKey as string);

    type OT = ObservableType<T[OK]>;

    type Mixed = T & {
      [symbol]: BehaviorSubject<OT>;
    } & Record<OK, BehaviorSubject<OT>>;

    // eslint-disable-next-line prefer-const
    let oPropertyValue: OT;

    Object.defineProperty(target, oPropertyKey, {
      enumerable: true,
      configurable: true,
      get(this: Mixed) {
        return (
          this[symbol] ||
          (this[symbol] = new BehaviorSubject(
            // when no initialValue passed in, use the original property val
            initialValue === undefined ? oPropertyValue : initialValue
          ))
        );
      },
      set(this: Mixed, value: OT) {
        this[oPropertyKey].next(value);
      }
    });

    if (!propertyKey) {
      return;
    }

    if (propertyKey === true) {
      propertyKey = (oPropertyKey as string).replace(/\$+$/, '') as K;
    }

    const oDescriptor = strictCheckDescriptor(target, propertyKey);
    oPropertyValue = oDescriptor ? oDescriptor.value : target[propertyKey];

    Object.defineProperty(target, propertyKey, {
      enumerable: true,
      configurable: true,
      get(this: Mixed) {
        return this[oPropertyKey].getValue();
      },
      set(this: Mixed, value: OT) {
        this[oPropertyKey].next(value);
      }
    });
  };
}
