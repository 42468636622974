import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarService } from "../../core/services/snackbar.service";

@Component({
  selector: 'dd-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private readonly snackBarService: SnackBarService
  ) {
  }

  triggerAction() {
    this.snackBarService.dismissWithAction();
  }

  dismiss() {
    this.snackBarService.dismiss();
  }
}
