import { Component, inject, OnInit } from '@angular/core';
import { UserDetailsService } from '../../../core/services/user-details.service';
import { catchError } from 'rxjs';
import { ToastService } from '../../../ui-kit/toast/toast.service';
import { SentryService } from '../../../core/services/sentry.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

export enum EmailProvider {
  GMAIL = 'gmail',
  OUTLOOK = 'outlook',
  YAHOO = 'yahoo'
}

@Component({
  selector: 'dd-verify-email-instructions',
  templateUrl: './verify-email-instructions.component.html'
})
export class VerifyEmailInstructionsComponent implements OnInit {

  buttonsConfig = [
    { id: EmailProvider.GMAIL, icon: '/assets/images/gmail-icon.png', alt: 'Gmail icon', label: 'Gmail' },
    { id: EmailProvider.OUTLOOK, icon: '/assets/images/outlook-icon.png', alt: 'Outlook icon', label: 'Outlook' },
    { id: EmailProvider.YAHOO, icon: '/assets/images/yahoo-icon.png', alt: 'Yahoo icon', label: 'Yahoo' }
  ];

  private cookieService = inject(CookieService);

  private email: string;


  constructor(private userDetailsService: UserDetailsService,
              private toastService: ToastService,
              private sentryService: SentryService,
              private router: Router,
  ) { }

  ngOnInit() {
    this.getEmailFromCookie();
  }

  openEmailProvider(id: EmailProvider) {
    const emailProviders = {
      [EmailProvider.GMAIL]: () => window.open('https://mail.google.com', '_blank'),
      [EmailProvider.OUTLOOK]: () => window.open('https://outlook.live.com', '_blank'),
      [EmailProvider.YAHOO]: () => window.open('https://mail.yahoo.com', '_blank')
    };

    emailProviders[id]?.();
  }

  resendEmail() {
    this.userDetailsService.resendVerificationEmail(this.email).pipe(
      catchError(() => {
        this.toastService.error('There was an error resending the verification email. If it persists, please contact the admins!');
        return this.sentryService.sendInfoMessage('Problem resending verification email');
      })).subscribe(() => {
      this.toastService.success('Email resent successfully. Please check your inbox / spam folders!');
    });
  }

  private getEmailFromCookie() {
    const email = this.cookieService?.get('email');
    if (!email) {
      this.toastService.error('There was a problem loading this page. If it persists, please contact the admins!');
      this.router.navigate(['/login']);
      return;
    }

    this.email = JSON.parse(email);
  }


}
