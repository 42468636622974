export const dataCenterRoute = {
  root: 'data-center',
  indexes: 'indexes',
  scrapeMatching: 'scrape-matching',
  indexExtension: 'index-extension',
  historicalData: 'historical-data',
  hierarchy: 'hierarchy',
  platformClasses: 'platform-classes',
  additionalLFCCondition: 'additional-LFC-condition',
  unit: 'unit',
  currency: 'currency',
  nation: 'nation',
  indexProvider: 'index-provider',
  users: 'users',
  permissions: 'permissions',
  apiKey: 'api-key',
  redListDetection: 'red-list-detection',
  dataGovernance: 'data-governance',
  futures: 'futures'
};
