import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, Observable, ReplaySubject, switchMap, tap } from 'rxjs';

import isEmpty from 'lodash-es/isEmpty';

import { AccountTab, LoginResponse, UserDetails } from '../models/auth.model';
import { Permissions } from '../models/permission.model';
import { userRoute } from '../routes/user.route';
import { CompanyService } from './company.service';
import { LocalStorageService } from './local-storage.service';
import { SnackBarService } from './snackbar.service';
import { UserDetailsService } from './user-details.service';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly currentUser$: Observable<UserDetails>;
  private redirectUrl = '';

  public readonly isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    Boolean(this.localStorageService.getSession())
  );
  private currentUser: UserDetails;
  private filledECPForm = false;
  private _onUserChange$ = new ReplaySubject<UserDetails>(1);

  constructor(
    private localStorageService: LocalStorageService,
    private companyService: CompanyService,
    private router: Router,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private userDetailsService: UserDetailsService,
  ) {
    this.currentUser$ = this._onUserChange$.asObservable();
  }

  login(email: string, password: string): Observable<HttpResponse<LoginResponse>> {
    return this.userDetailsService.login(email, password);
  }

  byPassTwoFactorAuthentication(response: LoginResponse) {
    this.setAuthSession(response);
  }

  validateOtp(payload: {code: string, otp: string}): Observable<LoginResponse> {
    return this.userDetailsService.validateOtp(payload).pipe(tap((res) => this.setAuthSession(res)));
  }

  setRedirectUrl(url: string) {
    this.redirectUrl = url;
  }

  getRedirectUrl() {
    return this.redirectUrl;
  }

  logout() {
    this.dialog.closeAll();
    this.localStorageService.killSession();
    this.isAuthenticated$.next(false);
    this.redirectUrl = '';
    this.router.navigate(['/login']);
  }

  isUserAuthenticated() {
    return Boolean(this.localStorageService.getSession());
  }

  getUserInfo(): Observable<UserDetails> {
    if (!isEmpty(this.currentUser)) {
      return this.currentUser$;
    }
    return this.userDetailsService.getUserInfo().pipe(
      switchMap((res) => {
        this.setUserInfo(res);
        return this.currentUser$;
      })
    );
  }

  setUserInfo(userDetails: UserDetails) {
    this.currentUser = userDetails;
    this._onUserChange$.next(userDetails);
    this.updateRollingReviewDealAccess(userDetails);
  }

  getCurrentUser(): UserDetails {
    return this.currentUser;
  }

  getUserPermissions(): Permissions {
    return <Permissions>this.currentUser?.permissions;
  }

  isUserLoaded() {
    return !!this.currentUser;
  }

  checkOnboardingStatus() {
    this.checkFilledECPForm().then(() => {
      const onboardingMessage = this.filledECPForm
        ? 'Thank you for finishing your onboarding requirements. You will be notified once the onboarding process is completed and be able to book a trade.'
        : 'Please complete the Regulatory Disclaimers section in your profile to finish the onboarding process prior to booking a trade.';
      const onboardingStatus = Boolean(this.currentUser.is_onboarded_completely);

      if (onboardingStatus) {
        return;
      }
      const snackbarDuration = { duration: this.snackBarService.getIndefiniteDuration() };
      const snackBarData = { message: onboardingMessage, actionLabel: 'Go to your Profile' };
      this.snackBarService.display(snackBarData, snackbarDuration);
      this.redirectAfterDismiss();
    });
  }

  private setAuthSession(res: LoginResponse): void {
    if (!res.access) return;
    this.localStorageService.setSession(res.access);
    this.isAuthenticated$.next(true);
    this.setUserInfo(res);
  }

  private redirectAfterDismiss() {
    this.snackBarService.listenToActionCalled().subscribe(() => {
      this.router.navigate([userRoute.root, userRoute.account, AccountTab.UserInfo]);
    });
  }

  private async checkFilledECPForm() {
    const company = await firstValueFrom(this.companyService.getCompany());
    this.filledECPForm =
      company?.ecp_disclaimer != null &&
      company?.has_financial_entity != null &&
      company?.has_meet_financial_obligations != null &&
      company?.is_authorized_signatory != null &&
      company?.is_mitigate_commercial_risk != null &&
      company?.is_special_entity != null &&
      company?.ubo != null;
  }

  private updateRollingReviewDealAccess(userDetails: UserDetails) {
    this.currentUser.permissions.rolling_deal_review_access = userDetails.has_rolling_deal_review_access;
  }
}
