import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateModule } from '@angular/material/core';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DateFnsDateTimeAdapter } from '@ng-matero/extensions-date-fns-adapter';
import { DatetimeAdapter, MTX_DATETIME_FORMATS } from '@ng-matero/extensions/core';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { MtxSelectModule } from '@ng-matero/extensions/select';
import { MtxSliderModule } from '@ng-matero/extensions/slider';
import { enUS } from 'date-fns/esm/locale';

const STABLE_DATE_FORMATS = {
  parse: {
    dateInput: ' yyyy-MM-dd',
    monthInput: 'MMMM',
    timeInput: 'HH:mm',
    datetimeInput: 'yyyy-MM-dd HH:mm'
  },
  display: {
    dateInput: ' yyyy-MM-dd',
    monthInput: 'MMMM',
    timeInput: 'HH:mm',
    datetimeInput: 'yyyy-MM-dd HH:mm',
    monthYearLabel: 'yyyy-MM-dd',
    dateA11yLabel: 'yyyy-MM-dd',
    monthYearA11yLabel: ' yyyy-MM-dd',
    popupHeaderDateLabel: 'MMM dd, ddd'
  }
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatIconModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatStepperModule,
    MatBadgeModule,
    MatCardModule,
    MatDatepickerModule,
    NativeDateModule,
    MatSliderModule,
    MatExpansionModule,
    MatRadioModule,
    MtxSliderModule,
    MtxSelectModule,
    MtxDatetimepickerModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatIconModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatStepperModule,
    MatBadgeModule,
    MatCardModule,
    MatDatepickerModule,
    NativeDateModule,
    MatSliderModule,
    MatExpansionModule,
    MatRadioModule,
    MtxSelectModule,
    MtxSliderModule,
    MtxDatetimepickerModule
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_LOCALE, useValue: enUS },
    {
      provide: DatetimeAdapter,
      useClass: DateFnsDateTimeAdapter
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    },
    { provide: MAT_DATE_FORMATS, useValue: STABLE_DATE_FORMATS },
    { provide: MTX_DATETIME_FORMATS, useValue: STABLE_DATE_FORMATS }
  ]
})
export class FormKitModule {}
