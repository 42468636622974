<div class="w-[900px] min-h-[700px] relative bg-white md:rounded-3xl md:shadow-md">
  <dd-side-panel></dd-side-panel>
  <div class="py-60 px-100 ml-300">
    <div class="text-35 font-bold mb-10">Reset Password</div>

    <p class="text-[12px] text-grey">Fill in your new password.</p>

    <div class="flex flex-col mt-30 gap-y-16">
      <form (ngSubmit)="sendLink()" [formGroup]="form">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Password</mat-label>
          <input formControlName="password" matInput type="password" />
          <mat-error *ngIf="form.controls.password?.hasError('required')">Password is required.</mat-error>
          <mat-error *ngIf="form.controls.password?.hasError('minlength')">
            Password must be at least 8 characters long.
          </mat-error>
        </mat-form-field>

        <ng-container *ngIf="form.get('password')?.hasError('passwordInvalid')">
          <dd-password-requirements></dd-password-requirements>
        </ng-container>

        <div class="mt-16">
          <button [disabled]="form.invalid || isLoading" class="w-full" color="primary" mat-flat-button>
            {{ isLoading ? 'Submitting...' : 'Change Password' }}
          </button>
        </div>
      </form>
      <p class="text-[12px] text-grey mt-30 text-right">Go back to <a class="text-blue-400 underline" routerLink="/login">Sign in</a></p>
    </div>
  </div>

</div>
