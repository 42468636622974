import { Component } from '@angular/core';

@Component({
  selector: 'dd-password-requirements',
  templateUrl: './password-requirements.component.html',
  standalone: true
})
export class PasswordRequirements {

}
