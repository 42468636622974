import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from "@angular/material/snack-bar";
import { SnackbarComponent } from "../../ui-kit/snackbar/snackbar.component";


export interface SnackBarData {
  message: string,
  actionLabel?: string
}
@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  // If duration is 0, Snackbar will be opened indefinitely, until action is taken
  private static INDEFINITE_SNACKBAR_DURATION = 0;

  private static DEFAULT_SNACKBAR_DURATION = 8000;

  private snackBarRef: MatSnackBarRef<any>;

  private snackBarConfig: MatSnackBarConfig = {
    politeness: 'polite',
    panelClass: 'style-success',
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
    duration: SnackBarService.DEFAULT_SNACKBAR_DURATION
  };

  constructor(private snackBar: MatSnackBar) {}

  display(snackBarData: SnackBarData, config?: MatSnackBarConfig) {
    this.createSnackbar(snackBarData, config);
  }

  dismissWithAction() {
    this.snackBarRef.dismissWithAction();
  }

  listenToActionCalled() {
    return this.snackBarRef.onAction();
  }

  getIndefiniteDuration() {
    return SnackBarService.INDEFINITE_SNACKBAR_DURATION;
  }

  dismiss() {
    this.snackBarRef.dismiss();
  }

  private createSnackbar(snackBarData: SnackBarData, config?: MatSnackBarConfig) {
    const customConfig = { ...this.snackBarConfig, ...(config) && config };

    this.snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
      data: { ...snackBarData, duration: customConfig?.duration },
      ...customConfig
    });
  }
}
