import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormKitModule } from '../../ui-kit/form-kit/form-kit.module';
import { AuthLayoutModule } from '../../layout/auth-layout/auth-layout.module';

import { AuthRoutingModule } from './auth-routing.module';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SidePanelModule } from './side-panel/side-panel.module';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VerifyEmailInstructionsComponent } from './verify-email-instructions/verify-email-instructions.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { SpinnerModule } from '../../ui-kit/spinner/spinner.module';
import { PasswordRequirements } from '../../standalone/components/password-instructions/password-requirements.component';
import { EnterVerifyCodeComponent } from './enter-verify-code/enter-verify-code.component';
import { CodeInputModule } from 'angular-code-input';


@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ContactUsComponent,
    ResetPasswordComponent,
    VerifyEmailInstructionsComponent,
    VerifyEmailComponent,
    EnterVerifyCodeComponent
  ],
  imports: [CommonModule, FormKitModule, AuthLayoutModule, AuthRoutingModule, SidePanelModule, SpinnerModule, PasswordRequirements, CodeInputModule]
})
export class AuthModule {
}
