export const portfolioManageSystemRoute = {
  root: 'portfolio-manage-system',
  home: 'home',
  summary: 'summary',
  premiumInsights: 'premium-insights',
  lossInsights: 'loss-insights',
  dealList: 'deal-list',
  dealDetails: 'deal-details',
  insightsByProduct: 'insights-by-product',
  reinsurerHomepage: 'reinsurer-homepage',
  bordereauReport: 'bordereau-report',
  contractPermission: 'contract-permission',
};
