import { Component } from '@angular/core';
import { UserGuard } from './core/guards/user.guard';

@Component({
  selector: 'dd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [UserGuard]
})
export class AppComponent {
  constructor() {}
}
