import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, finalize } from 'rxjs';

import { RegisterForm, registerFormGroup } from '../../../core/strict-typed-forms/auth.form';
import { ToastService } from '../../../ui-kit/toast/toast.service';
import { ToastPriority } from '../../../ui-kit/toast/toast.model';
import { UserDetailsService } from '../../../core/services/user-details.service';
import { SentryService } from '../../../core/services/sentry.service';
import { CookieService } from 'ngx-cookie-service';
import { ERROR_MESSAGES } from '../../../constants';

export interface RegistrationError {
  detail: any;
  error: string;
  error_code: string;
  message: string;
  sentry_event_id: string;
}

@Component({
  selector: 'dd-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  form: FormGroup<RegisterForm> = this.fb.nonNullable.group({ ...registerFormGroup });

  isLoading = false;

  private cookieService = inject(CookieService);

  constructor(
    private fb: FormBuilder,
    private userDetailsService: UserDetailsService,
    private toast: ToastService,
    private router: Router,
    private sentryService: SentryService
  ) {}

  register() {
    const { firstName, lastName, email, password } = this.form.value;
    const name = `${ firstName } ${ lastName }`.trim();

    if (!email || !password || !firstName) {
      return;
    }

    this.isLoading = true;

    this.userDetailsService
      .register(name, email, password)
      .pipe(
        catchError((e) => {
          const errorDetail = e?.error?.detail || ERROR_MESSAGES.GENERAL_ERROR;
          this.toast.error(errorDetail);
          return this.sentryService.sendException(e);
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(() => {
        this.toast.success(
          'Your account has successfully registered. Please check your inbox to validate your account.',
          ToastPriority.High
        );
        this.setCookieWithExpiry(email);
        this.router.navigate(['/verify-email-instructions']);
      });
  }

  private setCookieWithExpiry(email: string) {
    const cookieExpiryTime = new Date();
    cookieExpiryTime.setHours(cookieExpiryTime.getHours() + 1);

    this.cookieService.set('email', JSON.stringify(email), cookieExpiryTime);
  }
}
