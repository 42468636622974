import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { portfolioManageSystemRoute } from '../../core/routes/portfolio-manage-system.route';

@Component({
  selector: 'dd-pms-redirect',
  templateUrl: './pms-redirect.component.html',
  styleUrls: ['./pms-redirect.component.scss']
})
export class PMSRedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  redirectUrl: string;

  ngOnInit(): void {
    const [url] = this.route.snapshot.url;
    this.redirectUrl = `https://portfolio.stableprice.com/${portfolioManageSystemRoute.root}/${url.path}`;
  }
}
