import { Component, HostListener, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { catchError, finalize, interval, map, Observable, Subject, takeUntil, takeWhile, tap } from 'rxjs';

import { AuthService } from '../../../core/services/auth.service';
import { ToastService } from '../../../ui-kit/toast/toast.service';
import { SentryService } from '../../../core/services/sentry.service';
import { ToastPriority } from '../../../ui-kit/toast/toast.model';
import { UserDetailsService } from '../../../core/services/user-details.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { ERROR_MESSAGES } from '../../../constants';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'dd-enter-verify-code',
  templateUrl: './enter-verify-code.component.html',
  styleUrls: ['./enter-verify-code.component.scss']
})
export class EnterVerifyCodeComponent implements OnInit, OnDestroy {
  code: string;
  otp: string;
  timer$: Observable<number>;

  isLoading = false;
  isTimeFinished = false;
  isInvalidOtp = false;

  private stopTimer$ = new Subject<void>();
  private cookieService = inject(CookieService);

  constructor(
    private authService: AuthService,
    private router: Router,
    private sentryService: SentryService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private userDetailsService: UserDetailsService,
    private navigationService: NavigationService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
      const code = params?.get('code');
      this.setTemporaryCode(code);
    });
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  @HostListener('paste', ['$event'])
  listenPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    this.otp = clipboardData?.getData('text') || '';
  }

  startTimer(seconds: number): Observable<number> {
    this.isTimeFinished = false;
    this.timer$ = interval(1000).pipe(
      takeUntil(this.stopTimer$),
      map((value) => seconds - value),
      takeWhile((value) => value >= 0),
      tap((value) => {
        if (value === 0) {
          this.isTimeFinished = true;
        }
      })
    );
    return this.timer$;
  }

  stopTimer() {
    this.stopTimer$.next();
    this.stopTimer$.complete();
  }

  onOtpChanged(otp: string) {
    this.otp = otp;
  }

  onOtpCompleted(otp: string) {
    this.validate(otp);
  }

  async validate(otp: string) {
    this.isLoading = true;

    const payload = {
      code: this.code,
      otp
    };

    this.authService.validateOtp(payload).pipe(
      catchError((error) => {
        this.isInvalidOtp = true;
        this.otp = '';
        this.sentryService.sendInfoMessage('User was not able to validate otp');
        return this.sentryService.sendException(error);
      }),
      finalize(() => this.isLoading = false)
    ).subscribe( (response) => {
      this.navigationService.handleLoginRedirection(response);
      this.cookieService.delete('email');
    });
  }

  async resendAuthEmail() {
    this.isLoading = true;

    this.userDetailsService
      .resendOtp(this.code)
      .pipe(
        catchError((e) => {
          this.stopTimer();
          this.isLoading = false;
          const errorDetail = e?.error?.detail || ERROR_MESSAGES.GENERAL_ERROR;
          this.toastService.error(errorDetail);
          return this.sentryService.sendException(e);
        })
      )
      .subscribe(() => {
        this.toastService.info('Email with code sent! Enter the code again.');
        this.restartTimer();
        this.otp = '';
      });
  }

  private restartTimer() {
    this.isTimeFinished = false;
    this.startTimer(60);
  }

  private setTemporaryCode(code: string | null | undefined) {
    if (code) {
      this.code = code;
      this.startTimer(60);
    } else {
      this.redirectToLoginPage();
    }
  }

  private redirectToLoginPage() {
    this.router.navigate(['/login']).then(() => {
      this.toastService.error(
        'We encountered an issue during the login process. Please re-enter your credentials. If the error persists, contact Stable team.',
        ToastPriority.High);
    });
  }
}
