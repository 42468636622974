import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { catchError, finalize } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { ToastService } from '../../../ui-kit/toast/toast.service';
import { ForgotPasswordForm, forgotPasswordFormGroup } from '../../../core/strict-typed-forms/auth.form';
import { UserDetailsService } from '../../../core/services/user-details.service';
import { SentryService } from '../../../core/services/sentry.service';
import { ERROR_MESSAGES } from '../../../constants';

@Component({
  selector: 'dd-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  form: FormGroup<ForgotPasswordForm> = this.fb.nonNullable.group({ ...forgotPasswordFormGroup });

  isLoading = false;

  showInstructions = false;

  private email: string;

  constructor(
    private fb: FormBuilder,
    private userDetailsService: UserDetailsService,
    private toast: ToastService,
    private sentryService: SentryService,
    private cookieService: CookieService,
  ) {
  }

  async sendLink() {
    const { email } = this.form.value;

    if (!email) {
      return;
    }
    this.setCookieWithExpiry(email);

    if (!this.cookieHasEmail()) {
      this.toast.error('There was a problem processing your request. Kindly input the email and try again. ' +
        'If it persists, please contact the admins!');
      return;
    }

    this.isLoading = true;

    this.userDetailsService.forgotPassword(this.email).pipe(
      catchError((e) => {
        const errorDetail = e?.error?.detail || ERROR_MESSAGES.GENERAL_ERROR;
        this.toast.error(errorDetail);
        return this.sentryService.sendException(e);
      }), finalize(() => this.isLoading = false)
    ).subscribe(() => {
      this.toast.success('We have sent a reset password email. Please check your inbox.');
      this.showInstructions = true;
    });
  }

  private cookieHasEmail() {
    const email = this.cookieService?.get('email');
    this.email = JSON.parse(email);
    return !!email;
  }

  private setCookieWithExpiry(email: string) {
    const cookieExpiryTime = new Date();
    cookieExpiryTime.setHours(cookieExpiryTime.getHours() + 1);

    this.cookieService.set('email', JSON.stringify(email), cookieExpiryTime);
  }

}
