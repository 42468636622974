import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { LoginResponse, UserDetails } from '../models/auth.model';
import { HttpSuccessResponse } from '../models/common.model';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
  constructor(private http: HttpClient) {}

  login(email: string, password: string): Observable<HttpResponse<LoginResponse>> {
    const url = `${environment.api}/user/login/`;
    return this.http.post<LoginResponse>(
      url,
      { email, password, domain: window.location.host },
      { observe: 'response' }
    );
  }

  register(name: string, email: string, password: string): Observable<HttpSuccessResponse> {
    const url = `${environment.api}/user/register/`;
    return this.http.post<HttpSuccessResponse>(url, { name, email, password, domain: window.location.host });
  }

  forgotPassword(email: string): Observable<HttpSuccessResponse> {
    const url = `${environment.api}/user/forgot-password/`;
    const payload = { email, domain: window.location.host };
    return this.http.post<HttpSuccessResponse>(url, payload);
  }

  resetPassword(password: string, userId: string, changePasswordId: string): Observable<HttpSuccessResponse> {
    const url = `${environment.api}/user/reset-password/${changePasswordId}/?user_id=${userId}`;
    const payload = { password };
    return this.http.post<HttpSuccessResponse>(url, payload);
  }

  verifyEmail(verificationId: string, userId: string) {
    const url = `${environment.api}/user/verify/${verificationId}?user_id=${userId}`;
    return this.http.get<HttpSuccessResponse>(url);
  }

  resendVerificationEmail(email: string) {
    const url = `${environment.api}/user/resend-verification-email/`;
    return this.http.post<HttpSuccessResponse>(url, { email });
  }

  changePassword(password: string, current_password: string): Observable<HttpResponse<any>> {
    const url = `${environment.api}/user/change-password/`;
    return this.http.post<HttpResponse<any>>(url, { password, current_password });
  }

  changeAvatar(formData: any): Observable<LoginResponse> {
    const url = `${environment.api}/user/update-avatar/`;
    return this.http.post<LoginResponse>(url, formData);
  }

  updateUser(body: { name?: string; phone_number?: string; is_2fa_enabled?: boolean }): Observable<HttpResponse<any>> {
    const url = `${environment.api}/user/update/`;
    return this.http.patch<HttpResponse<any>>(url, body);
  }

  getUserInfo() {
    const url = `${environment.api}/user/`;
    return this.http.get<UserDetails>(url);
  }

  validateOtp(payload: { code: string; otp: string }): Observable<LoginResponse> {
    const url = `${environment.api}/user/validate-otp/`;
    return this.http.post<LoginResponse>(url, payload);
  }

  resendOtp(code: string): Observable<LoginResponse> {
    const url = `${environment.api}/user/resend-otp/`;
    return this.http.post<LoginResponse>(url, { code });
  }
}
