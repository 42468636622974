import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dd-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
  @Input() bgClass = 'bg-slate-200';
  @Input() message: string;
  @Input() spinner: boolean = true;

  constructor() {}
}
