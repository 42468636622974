import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { getActiveTransaction, init } from "@sentry/angular-ivy";
import { BrowserTracing } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
  enableSentry();
}


const activeTransaction = getActiveTransaction();
const bootstrapSpan =
  activeTransaction &&
  activeTransaction.startChild({
    description: "platform-browser-dynamic",
    op: "ui.angular.bootstrap",
  });

function enableSentry() {
  init({
    dsn: environment.sentry.dsn,
    release: environment.sentry.release,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    integrations: [new BrowserTracing({
      tracePropagationTargets: [environment.baseUrl],
    })],

    environment: environment.sentry.environment,
  });


}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
  .finally(() => {
    if (environment?.production && environment?.sentry && bootstrapSpan) {
      bootstrapSpan.finish();
    }
  });
